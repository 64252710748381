import * as ScrollArea from '@radix-ui/react-scroll-area';
import clsx from 'clsx';
import type { PropsWithChildren, RefObject } from 'react';

import * as styles from './index.css';
import { useHasScrollTop } from './use-has-scroll-top';

export type ScrollableContainerProps = {
  showScrollTopBorder?: boolean;
  inTableView?: boolean;
  className?: string;
  viewPortClassName?: string;
  styles?: React.CSSProperties;
  scrollBarClassName?: string;
  // DNB_FIX добавить scrollRef
  scrollRef?: RefObject<HTMLDivElement>;
  // DNB_FIX добавить scrollableContainerClassName
  scrollableContainerClassName?: string;
};

export const ScrollableContainer = ({
  children,
  showScrollTopBorder = false,
  inTableView = false,
  className,
  styles: _styles,
  viewPortClassName,
  scrollBarClassName,
  scrollableContainerClassName,
  scrollRef,
}: PropsWithChildren<ScrollableContainerProps>) => {
  const [setContainer, hasScrollTop] = useHasScrollTop();

  return (
    <ScrollArea.Root
      style={_styles}
      className={clsx(styles.scrollableContainerRoot, className)}
    >
      <div
        data-has-scroll-top={hasScrollTop}
        className={clsx({ [styles.scrollTopBorder]: showScrollTopBorder })}
      />
      {/* DNB_FIX прокинуть scrollRef */}
      <ScrollArea.Viewport
        className={clsx([styles.scrollableViewport, viewPortClassName])}
        ref={scrollRef ? scrollRef : setContainer}
      >
        {/* DNB_FIX добавить класс scrollableContainerClassName */}
        <div
          className={clsx(
            styles.scrollableContainer,
            scrollableContainerClassName
          )}
        >
          {children}
        </div>
      </ScrollArea.Viewport>
      <ScrollArea.Scrollbar
        orientation="vertical"
        className={clsx(styles.scrollbar, scrollBarClassName, {
          [styles.TableScrollbar]: inTableView,
        })}
      >
        <ScrollArea.Thumb className={styles.scrollbarThumb} />
      </ScrollArea.Scrollbar>
    </ScrollArea.Root>
  );
};
