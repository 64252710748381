import { DebugLogger } from '@affine/debug';
import { UserFriendlyError } from '@affine/graphql';

import { BackendError, NetworkError } from './error';
import type { FetchInit } from './types';
const logger = new DebugLogger('affine:fetch');

export function getAffineCloudBaseUrl(): string {
  if (environment.isDesktop) {
    return runtimeConfig.serverUrlPrefix;
  }
  const { protocol, hostname, port } = window.location;
  return `${protocol}//${hostname}${port ? `:${port}` : ''}`;
}

// DNB_FIX добавить hook по работе с timeout
export const getTimeout = (
  timeout?: number,
  abortController = new AbortController()
) => {
  const existingTimeout = !Number.isSafeInteger(timeout);

  const _timeout = timeout ?? 15000;
  let timeoutId: ReturnType<typeof setTimeout> | null = null;

  const set = () => {
    if (!existingTimeout) return;

    timeoutId = setTimeout(() => {
      abortController.abort('timeout');
    }, _timeout);
  };

  const clear = () => {
    if (!existingTimeout || !timeoutId) return;

    clearTimeout(timeoutId);
  };

  return { clear, set };
};

export const fetchCloud = async (
  input: string,
  init?: FetchInit
): Promise<Response> => {
  logger.debug('fetch', input);
  const externalSignal = init?.signal;
  if (externalSignal?.aborted) {
    throw externalSignal.reason;
  }
  const abortController = new AbortController();
  externalSignal?.addEventListener('abort', reason => {
    abortController.abort(reason);
  });

  // DNB_FIX использовать hook useTimeout
  const { clear, set } = getTimeout(init?.timeout);

  set();

  const res = await fetch(new URL(input, getAffineCloudBaseUrl()), {
    ...init,
    signal: abortController.signal,
  }).catch(err => {
    logger.debug('network error', err);
    throw new NetworkError(err);
  });

  clear();

  if (res.status === 504) {
    const error = new Error('Gateway Timeout');
    logger.debug('network error', error);
    throw new NetworkError(error);
  }
  if (!res.ok) {
    logger.warn('backend error', new Error(`${res.status} ${res.statusText}`));
    let reason: string | any = '';
    if (res.headers.get('Content-Type')?.includes('application/json')) {
      try {
        reason = await res.json();
      } catch (err) {
        // ignore
      }
    }
    throw new BackendError(UserFriendlyError.fromAnyError(reason));
  }
  return res;
};
